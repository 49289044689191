<script>
import Datepicker from '@/components/Datepicker'
import { Mixin } from '@/mixins/util.js'
import {
  Table,
  TableColumn,
  Button,
  Dialog,
  Progress,
  RadioGroup,
  RadioButton
} from 'element-ui'
export default {
  name: 'detail',
  mixins: [Mixin],
  components: {
    Datepicker,
    'el-table': Table,
    'el-table-column': TableColumn,
    'el-button': Button,
    'el-dialog': Dialog,
    'el-progress': Progress,
    'el-radio-group': RadioGroup,
    'el-radio-button': RadioButton
  },
  data() {
    return {
      filterTag: '全部',
      timer: [],
      progress: 0,
      Colors: [
        { color: '#f56c6c', percentage: 20 },
        { color: '#e6a23c', percentage: 40 },
        { color: '#5cb87a', percentage: 60 },
        { color: '#1989fa', percentage: 80 },
        { color: '#6f7ad3', percentage: 100 }
      ],
      isDwnDone: false,
      colConfigs: [
        { prop: 'idx', label: '#', width: '80' },
        { prop: '0', label: 'セット商品名', width: '280' },
        { prop: '1', label: 'セット商品コード', width: '140' },
        { prop: '2', label: '商品來源', width: '100' },
        { prop: '3', label: 'セット数', width: '' }
      ],
      fee_not_show: [
        'コールセンター対応費用', // 單價100 數量 お客様対応一覧!D2-100 ->100固定?
        '委託販売手数料', //type1 当月売上高 * 手續費  type2 ( 当月実売上高 (JPY決済) + 当月実売上高 (NTD決済) ) * 手續費
        '客服電話對應件數', // 數量 お客様対応一覧!D2
        '客服郵件對應件數', // 數量 お客様対応一覧!E2
        '客服對應條件' // 單價100 お客様対応一覧!D2-100 ->100固定?
      ],
      cur_page: 1,
      charge: '0.3' * 1,
      exchangeRate: '3.46' * 1,
      startDate: '2019-12-01',
      type1: [
        '社名',
        '電話番号',
        '振り込み先銀行名',
        'SWIFT CODE',
        '支店名',
        '支店コード',
        '銀行住所',
        '口座番号',
        '住所'
      ],
      type2: ['社名', '振り込み先銀行名', '支店名', '種別', '口座番号'],
      type: sessionStorage.getItem('supplier_type') * 1,
      table_1: [],
      table_4: [],
      table_105: [],
      table_106: [],
      table_11: [],
      table_12: [],
      table_13: [],
      table_19: [],
      table_24: [],
      table_117: [],
      table_118: [],
      table_97: [],
      table_98: []
    }
  },
  computed: {
    filterData() {
      if (this.TotableData.length === 0) return []
      if (this.filterTag === '全部') return this.TotableData
      let data = this.TotableData.map(item => JSON.parse(JSON.stringify(item)))
      return data.filter(item => {
        return item['2'] === this.filterTag
      })
    },
    TotableData() {
      let tableData = this.table_1_edit.map((items, index) => {
        let temp = items.map((item, i) => {
          if (i === 2) {
            if (item === '001_000001_000_00000000') return 'Launch cart'
            else if (item === '001_000031_000_00000000') return 'Line'
            else if (item === '001_000033_000_00000000') return 'MOMO'
            else return 'Launch cart'
          }
          return item.toLocaleString()
        })
        let temp1 = { ...temp }
        temp1['idx'] = index + 1
        return temp1
      })
      if (this.table_1_LC_total.length > 0) {
        let total = { 0: 'LC合計' },
          idx = 3
        for (let i of this.table_1_LC_total) {
          total[`${idx++}`] = i.toLocaleString()
        }
        total['2'] = 'Launch cart'
        tableData.push(total)
      }
      if (this.table_1_MOMO_total.length > 0) {
        let total = { 0: 'MOMO合計' },
          idx = 3
        for (let i of this.table_1_MOMO_total) {
          total[`${idx++}`] = i.toLocaleString()
        }
        total['2'] = 'MOMO'
        tableData.push(total)
      }
      if (this.table_1_total.length > 0) {
        let total = { 0: '全部合計' },
          idx = 3
        for (let i of this.table_1_total) {
          total[`${idx++}`] = i.toLocaleString()
        }
        tableData.push(total)
      }

      return tableData
    },
    colTitle() {
      //  { prop: '2', label: 'セット数', width: '' }
      let index = 4
      let colConfigs = this.colConfigs.map(item => item)
      if (this.table_24.length > 0) {
        for (let item of this.table_24[0])
          colConfigs.push({ prop: `${index++}`, label: item, width: '' })
      }
      if (this.type === 1)
        colConfigs.push({ prop: `${index}`, label: '売上(NTD)', width: '120' })
      else
        colConfigs.push({ prop: `${index}`, label: '売上(JPY)', width: '120' })
      return colConfigs
    },
    table_1_edit() {
      let table_1_edit = this.table_1.map(items => {
        items.splice(2, 1)
        return items
          .filter((item, i) => {
            if (i > 6) return item
            return true
          })
          .map((item, i) => {
            if (i <= 1) return item
            return item * 1
          })
      })
      return table_1_edit
    },
    table_1_total() {
      if (this.table_1_edit.length !== 0) {
        let table = this.table_1_edit.map(items => items.map(item => item))
        let total_list = table.map(items => {
          items.splice(0, 3)
          return items
        })
        return this.sum_data(total_list)
      }
      return [0]
    },
    table_1_LC_total() {
      if (this.table_1_edit.length === 0) return 0
      return this.sum_data(
        this.table_1_edit
          .map(items => items.map(item => item))
          .filter(item => item[2] === '001_000001_000_00000000')
          .map(items => {
            items.splice(0, 3)
            return items
          })
      )
    },
    table_1_MOMO_total() {
      if (this.table_1_edit.length === 0) return 0
      return this.sum_data(
        this.table_1_edit
          .map(items => items.map(item => item))
          .filter(item => item[2] === '001_000033_000_00000000')
          .map(items => {
            items.splice(0, 3)
            return items
          })
      )
    },
    table_4_edit() {
      if (this.table_4.length !== 0) {
        let temp
        return this.table_4.map(items => {
          if (isNaN(items[2] * 1)) temp = '-'
          else temp = items[2] * 1
          let productName = null
          if (typeof items[0] === 'object') productName = items[0].tw
          else productName = items[0]

          return [
            productName,
            items[1],
            temp,
            items[3] * 1,
            items[4] * 1,
            items[5] * 1
          ]
        })
      }
      return []
    },
    table_105_edit() {
      if (this.table_105.length === 0) return []
      let data = []
      this.table_105.map(item => {
        let temp = []
        temp.push(item[0])
        if (item[2] === 'NTD') {
          temp.push(item[1] * this.exchangeRate)
          temp.push(item[1])
        } else {
          temp.push(item[1])
          temp.push(item[1] * this.exchangeRate)
        }
        temp.push(item[6])
        temp.push(temp[2] * temp[3])
        data.push(temp)
      })
      return data
    },
    table_105_total() {
      if (this.table_105_edit.length === 0) return 0
      return this.table_105_edit.reduce((p, c) => {
        if (isNaN(p[4] + c[4])) return p + c[4]
        return p[4] + c[4]
      }, 0)
    },
    table_106_edit() {
      if (this.table_106.length === 0) return []
      let data = []
      this.table_106.map(item => {
        let temp = []
        temp.push(item[0])
        if (item[2] === 'NTD') {
          temp.push(item[1])
          temp.push(item[1] * this.exchangeRate)
        } else {
          temp.push('')
          temp.push(item[1])
        }
        temp.push(item[5])
        temp.push(temp[2] * temp[3])
        data.push(temp)
      })
      return data
    },
    table_106_total() {
      if (this.table_106_edit.length === 0) return 0
      return this.table_106_edit.reduce((p, c) => {
        if (isNaN(p[4] + c[4])) return p + c[4]
        return p[4] + c[4]
      }, 0)
    },
    table_11_edit() {
      if (this.table_11.length !== 0) {
        let table_11_edit = []
        const temp = this.table_11[1][0].match(/\d+/g).map(n => parseInt(n))
        const sum = (temp[0] - temp[1]) * this.exchangeRate
        this.$set(table_11_edit, 0, this.table_11[0][0] * 1)
        this.$set(table_11_edit, 1, sum)
        return table_11_edit
      }
      return []
    },
    table_117_edit() {
      if (this.table_117.length !== 0) {
        let temp = this.table_117.map(item => {
          const title = item[0],
            currency = item[2]
          let price = parseFloat(item[1]).toFixed() * 1 || 0,
            count = '',
            total
          if (item[5] !== '') count = parseInt(item[5])
          total = price * count * -1 || 0
          if (total === 0) total = Math.abs(total)
          if (this.fee_not_show.includes(title) === false) {
            if (currency === 'NTD') {
              return [
                title,
                '',
                `${this.$children[2].month}月`,
                price,
                count,
                total
              ]
            } else if (currency === 'JPY') {
              let t
              let NTD = price / this.exchangeRate || 0
              NTD = NTD.toFixed() * 1
              t = NTD * count
              if (count === '') t = NTD
              return [
                title,
                price,
                `${this.$children[2].month}月`,
                NTD,
                count,
                t * -1
              ]
            }
          } else if (title === '委託販売手数料') {
            price =
              this.table_1_total[this.table_1_total.length - 1] * this.charge
            total = price * count * -1 === 0 ? 0 : price * count * -1
            if (isNaN(price)) total = price = '-'

            return [
              'LC販売手数料',
              '',
              `${this.$children[2].month}月`,
              Math.round(price),
              count,
              Math.round(total)
            ]
          }
        })
        if (this.table_1_MOMO_total.length > 0) {
          let d = this.table_1_MOMO_total[this.table_1_MOMO_total.length - 1]
          let charge = 0.55
          let price = Math.round(d * charge)
          temp.push([
            'MOMO販売手数料',
            '',
            `${this.$children[2].month}月`,
            price,
            1,
            -1 * price
          ])
        }

        return temp.filter(item => item !== undefined)
      }
      return []
    },
    table_117_total() {
      if (this.table_117_edit.length > 0) {
        return this.table_117_edit
          .map(item => {
            if (isNaN(item[5])) return 0
            else return item[5]
          })
          .reduce((a, b) => a + b)
      }
      return 0
    },
    table_118_edit() {
      if (this.table_118.length !== 0) {
        let temp = this.table_118.map(item => {
          const title = item[0],
            currency = item[2]
          let price = parseFloat(item[1]).toFixed() * 1 || 0,
            count = parseInt(item[5]) || 0,
            total = price * count * -1 || 0
          if (total === 0) total = Math.abs(total)
          if (this.fee_not_show.includes(title) === false) {
            if (currency === 'JPY') {
              return [
                title,
                '',
                `${this.$children[2].month}月`,
                price,
                count,
                total
              ]
            } else if (currency === 'NTD') {
              let JPY = price * this.exchangeRate || 0
              JPY = JPY.toFixed() * 1
              return [
                title,
                price,
                `${this.$children[2].month}月`,
                JPY,
                count,
                total
              ]
            }
          } else if (title === '委託販売手数料') {
            let price =
              (this.table_11_edit[0] + this.table_11_edit[1]) * this.charge
            total = price * count * -1
            if (total === 0) total = Math.abs(total)
            return [
              title,
              '',
              `${this.$children[2].month}月`,
              Math.round(price),
              count,
              Math.round(total)
            ]
          }
        })
        return temp.filter(item => item !== undefined)
      }
      return []
    },
    table_118_total() {
      if (this.table_118_edit.length > 0) {
        return this.table_118_edit.map(item => item[5]).reduce((a, b) => a + b)
      }
      return 0
    },
    table_19_edit() {
      if (this.table_19.length !== 0) {
        let temp = this.table_19.map(items => {
          return [...items, items[3] * 1 * (items[4] * 1)]
        })
        return temp
      }
      return []
    },
    table_19_total() {
      if (this.table_19_edit.length !== 0) {
        return this.table_19_edit.map(items => items[5]).reduce((a, b) => a + b)
      }
      return 0
    },
    table_1234() {
      //1 table_1_total
      //2 費用 table_17_total
      //3 營業稅
      //4 調整
      let a = this.table_1_total[this.table_1_total.length - 1] || 0
      return this.type === 1
        ? a + this.table_117_total + this.table_19_total + this.table_105_total
        : a + this.table_117_total + this.table_19_total + this.table_106_total
    },
    table_2345() {
      //2 topOfMonth_JPY
      //3 topOfMonth_NTD
      //4 費用 table_18_total
      //5 調整
      return this.type === 1
        ? this.table_11_edit[0] +
            this.table_11_edit[1] +
            this.table_118_total +
            this.table_105_total
        : this.table_11_edit[0] +
            this.table_11_edit[1] +
            this.table_118_total +
            this.table_106_total
    }
  },
  methods: {
    clearTimer() {
      if (this.timer.length > 0) {
        for (const t of this.timer) {
          clearTimeout(t)
        }
        this.timer = []
      }
    },
    simProgress() {
      let addValue = Math.floor(Math.random() * 3)
      let time = Math.floor(Math.random() * 550)
      if (this.progress + addValue <= 100) {
        this.progress += addValue
        setTimeout(this.simProgress, time)
      } else {
        this.progress = 100
        this.clearTimer()
      }
    },
    dwnHandler() {
      let year = this.$children[2].year,
        month = this.$children[2].month
      let endDay = new Date(year, month, 0).getDate()
      const s_id = sessionStorage.getItem('session_id')
      const s_uid = parseInt(
        sessionStorage.getItem('pub_permissions').substr(1)
      )
      const _hostname = `${this.hostname}/app/hermers/datarefine_export_xlfiles`

      //export_xlfile_name
      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain;charset=UTF-8'
        },
        body: `sessionid=${s_id}&supplier_uid=${s_uid}&export_fmt_alias=報表&begin_year=${year}&begin_month=${month}&begin_date=1&end_year=${year}&end_month=${month}&end_date=${endDay}`
      }
      this.isDwnDone = true
      this.progress = 0
      this.simProgress()
      fetch(_hostname, config)
        .then(res => res.text())
        .then(res => {
          const data = JSON.parse(this.RemoveNonValidJSONChars(res))
          const dist = `${this.hostname.replace(':8080', '')}/cymmercebiz`
          const distFileURL = dist + data.export_xlfile_name
          const file = document.createElement('a')
          file.classList.add('dwnlink')
          file.href = distFileURL
          file.download = `${year}-${month}.xlsx`
          document.body.appendChild(file)

          setTimeout(() => {
            let link = document.querySelector('.dwnlink')
            link.click()
            document.getElementsByClassName('dwnlink')[0].remove()
            this.isDwnDone = false
          }, 1000 * 20)
        })
    },
    sum_data(dataList) {
      return dataList.reduce(function(r, a) {
        a.forEach(function(b, i) {
          r[i] = (r[i] || 0) + b * 1
        })
        return r
      }, [])
    },
    getChartDataWithTimeOut(y, m) {
      let report_id
      if (this.type === 1) report_id = '1,24,117,4,105,19,12,97,98'
      else if (this.type === 2) report_id = '1,24,118,4,106,11,13,97,98'

      const result = this.fetchWithTimeout(y, m, report_id)
      this.$eventBus.$emit('loadingHandler', true)
      result
        .then(res => res.text())
        .then(rep => {
          rep = JSON.parse(this.RemoveNonValidJSONChars(rep))
          this.$eventBus.$emit('loadingHandler', false)
          // console.log(rep)
          if (rep.errno !== '0') {
            this.$eventBus.$emit('logOutHandler', true)
            this.ToLoginPage()
          } else {
            const keys = Object.keys(rep).filter(item => !isNaN(item))
            keys.forEach(key => (this[`table_${key}`] = rep[key]))
            this.charge = this.table_98[0][0] * 1
            this.exchangeRate = eval(this.table_97[0][0].replace('=', '') * 1)
            this.table_1.map(item => {
              item[0] = this.Mosaic(
                item[0],
                sessionStorage.getItem('session_id')
              )
              item[1] = this.Mosaic(
                item[1],
                sessionStorage.getItem('session_id')
              )
              return item
            })
            this.table_4.map(item => {
              item[0] = this.Mosaic(
                item[0],
                sessionStorage.getItem('session_id')
              )
              item[1] = this.Mosaic(
                item[1],
                sessionStorage.getItem('session_id')
              )
              return item
            })
            this.table_19.map(item => {
              item[0] = this.Mosaic(
                item[0],
                sessionStorage.getItem('session_id')
              )
              item[1] = this.Mosaic(
                item[1],
                sessionStorage.getItem('session_id')
              )
              return item
            })
            this.table_12.map(item => {
              item[0] = this.Mosaic(
                item[0],
                sessionStorage.getItem('session_id')
              )
              item[1] = this.Mosaic(
                item[1],
                sessionStorage.getItem('session_id')
              )
              return item
            })
          }
        })
        .catch(() => {
          this.$eventBus.$emit('loadingHandler', false)
          this.$eventBus.$emit('serverDownHandler', true)
          this.ToLoginPage()
        })
    },
    cellStyle({ row, columnIndex }) {
      let cell = row[columnIndex - 1]
      if (cell && columnIndex > 2) {
        cell = cell.replace(',', '') * 1
        if (!isNaN(cell) && cell < 0) return 'color: red;text-align:right;'
        else return 'text-align:right;'
      }
      // if (cell && columnIndex === 3) return 'text-align:center;'
    },
    ToLoginPage() {
      this.timer = setTimeout(() => {
        this.$router.push({
          name: 'login',
          params: { t: '123' }
        })
      }, 2500)
    }
  },
  async created() {
    const y = new Date().getFullYear()
    const m = new Date().getMonth() + 1

    this.getChartDataWithTimeOut(y, m)
  },
  mounted() {
    const menu = document.querySelector('.menu')
    const ham = document.querySelector('.ham')
    menu.style.left = '-320px'
    ham.style.display = 'block'
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  }
}
</script>

<template src="./template.html"></template>
<style lang="scss" src="./style.scss" scoped></style>
